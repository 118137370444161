<template>
  <PageWithLayout>
    <PdfConverter ref="PdfConverter" :detailData="detailData" @sendPaymentCb="sendPaymentCb">
      <div class="section_comm">
        <div class="head_section">
          <h2 class="tit_section">기안 관리</h2>
        </div>
        <div class="body_section">
          <template>
            <TableViewWithTitle title="구매요청 정보">
              <template v-slot:body>
                <tr>
                  <th>구매요청서</th>
                  <td colspan="3">
                    <ConnectViewOnlyOne
                      :dataList="itemData.purchaseRequest"
                      @onClickConnect="onClickConnectPurchaseView"
                    />
                  </td>
                </tr>
                <tr>
                  <th>프로젝트 코드</th>
                  <td>
                    {{ itemData.projectCode }}
                  </td>
                  <th>프로젝트 명</th>
                  <td v-html="itemData.projectName"></td>
                </tr>
              </template>
            </TableViewWithTitle>
          </template>
          <template>
            <TableViewWithTitle title="기안관리 기본 정보">
              <template v-slot:body>
                <tr>
                  <th>기안명</th>
                  <td>{{ itemData.title }}</td>
                  <th>등록일</th>
                  <td>{{ itemData.regDate | dateStringFormat }}</td>
                </tr>
                <tr>
                  <th>기안자</th>
                  <td>{{ itemData.userName }}</td>
                  <th>기안부서</th>
                  <td>{{ itemData.deptName }}</td>
                </tr>
                <!-- <tr>
                  <th>구매구분</th>
                  <td>{{ itemData.assetTypeName }}</td>
                  <th>구매 카테고리</th>
                  <td>{{ itemData.itemTitle }}</td>
                </tr> -->
              </template>
            </TableViewWithTitle>
          </template>
          <TableViewWithTitle title="구매구분">
            <template v-slot:colgroup>
              <col style="width: 192px" />
              <col />
            </template>
            <template v-slot:body>
              <th>구매구분</th>
              <td>
                {{ itemData.assetTypeName | nullToDash }}
              </td>
            </template>
          </TableViewWithTitle>

          <template>
            <TableViewWithTitle title="기안 금액">
              <template v-slot:colgroup>
                <!-- No -->
                <col style="width: 62px" />
                <!-- 품명 -->
                <col />
                <!-- 품목코드 -->
                <col style="width: 138px" />
                <!-- 자산코드 -->
                <col style="width: 92px" />
                <!-- 단위 -->
                <col style="width: 164px" />
                <!-- 수량 -->
                <col style="width: 92px" />
                <!-- 단가 -->
                <col style="width: 92px" />
                <!-- 금액 -->
                <col style="width: 92px" />
                <!-- 품목상세 -->
                <col style="width: 200px" />
              </template>
              <template v-slot:header>
                <tr>
                  <th scope="col">No.</th>
                  <th scope="col">품명</th>
                  <th scope="col">품목코드</th>
                  <th scope="col">자산코드</th>
                  <th scope="col">수량</th>
                  <th scope="col">단가</th>
                  <th scope="col">금액</th>
                  <th scope="col">단위</th>
                  <th scope="col">품목상세</th>
                </tr>
              </template>

              <template v-slot:body>
                <tr v-for="(item, index) in itemData.purchaseGoodsList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.itemName | nullToDash }}</td>
                  <td>
                    <button
                      :class="['link_subject']"
                      @click="$windowOpen(`${$routerPath.ITEM_VIEW}/${item.itemCode}`)"
                    >
                      {{ item.displayCode }}
                    </button>
                  </td>
                  <td>{{ item.assetsCode | nullToDash }}</td>
                  <td>{{ item.unitValue | nullToDash }}</td>
                  <td>{{ item.quantity | currency | nullToDash }}</td>
                  <td>{{ item.price | currency }}</td>
                  <td>{{ item.totalPrice | currency }}</td>
                  <td :data-ellip="item.note">
                    {{ item.note | nullToDash }}
                  </td>
                </tr>
              </template>
              <template v-slot:foot>
                <div class="tbl_foot">
                  <div class="area_total">
                    <dl>
                      <dt>
                        총 기안금액
                        <!-- <span class="txt_info">(부가세 제외)</span> -->
                      </dt>
                      <dd>{{ itemData.totalDraftPrice | currency }}</dd>
                    </dl>
                  </div>
                </div>
              </template>
            </TableViewWithTitle>
          </template>

          <TableViewWithTitle title="상세내용">
            <template v-slot:body>
              <tr>
                <th scope="row">상세내용</th>
                <td colspan="3" v-html="noteDecoded" />
              </tr>
            </template>
          </TableViewWithTitle>

          <TableViewWithTitle title="첨부파일">
            <template v-slot:body>
              <tr>
                <th scope="row">첨부파일</th>
                <td colspan="3">
                  <FileView :dataList.sync="itemData.attachFileList" />
                </td>
              </tr>
            </template>
          </TableViewWithTitle>
        </div>
      </div>
      <Sticky>
        <div class="area_left">
          <button class="btn_fourthly btn_large" @click="onClickGoToList">목록</button>
          <template v-if="isMydraft">
            <template v-if="ingPayment">
              <button
                class="btn_primary btn_large"
                @click="
                  () => {
                    $router.push(`${computedPaymentPath}/${itemData.docNo}`);
                  }
                "
              >
                전자결재 - {{ itemData.docStatusName }}
              </button>
            </template>
            <template v-else>
              <button type="button" class="btn_primary btn_large" @click="onClickDraft">
                전자결재
              </button>
            </template>
          </template>
        </div>
        <div class="area_right">
          <template v-if="isMydraft && !ingPayment">
            <button class="btn_fourthly btn_large" @click="onClickModify">수정</button>
          </template>
        </div>
      </Sticky>
    </PdfConverter>
    <template v-slot:popup>
      <AlertPopup
        v-if="isDraftConfirm"
        alertText="전자결재 하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickClose"
        @onClickPrimary="onClickPdfConvert"
      />

      <AlertPopup
        v-if="isDraftGo"
        alertText="전자결재문서가 등록되었습니다.<br />전자결재를 진행하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickClose"
        @onClickPrimary="onClickPlaymentGo"
      />
    </template>
  </PageWithLayout>
</template>
<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";

import FileView from "@/components/common/file/FileView";
import TableHead from "@/components/shared/TableHead";
import ConnectViewOnlyOne from "@/components/common/connect/ConnectViewOnlyOne";
import Board from "@/components/common/board/Board";
import Sticky from "@/components/layout/content/Sticky.vue";
import AlertPopup from "@/components/layout/popup/AlertPopup";

import PurchaseRequestDetailPopup from "@/components/admin/purchaseRequest/popup/PurchaseRequestDetailPopup";

import CloseAnnouncePopup from "@/components/admin/bid/popup/CloseAnnouncePopup";

import PageMixin from "@/mixins/PageMixin";
import { getDateString, getDateStringWithTime } from "@/utils/dateUtils";
import { addCommaForMoney, decodeHTMLEntities, getIsValidHtml } from "@/utils/stringUtils";
import {
  getCategoryDepthText,
  getEvaluateData,
  getCountWithCurrency,
  getCountWithQuantity,
} from "@/utils/spUtils";
import { getPaymentRouterPath } from "@/utils/paymentPathUtils";

import ApiService from "@/services/ApiService";
import LocalStorageManager from "@/LocalStorageManager";

import { PROGRESS_START_ACTION, PROGRESS_END_ACTION } from "@/store/modules/progressbar/action";

import PdfConverter from "@/components/shared/PdfConverter";

export default {
  name: "DraftView",
  components: {
    PageWithLayout,
    TableViewWithTitle,
    // PurchaseJiraConnectView,
    TableHead,
    ConnectViewOnlyOne,
    Board,
    FileView,
    Sticky,
    AlertPopup,

    PurchaseRequestDetailPopup,
    // PurchaseRequestJiraDetailPopup,
    CloseAnnouncePopup,
    PdfConverter,
  },
  mixins: [PageMixin],
  data() {
    return {
      itemData: {},
      itemId: "",

      isDraftConfirm: false,
      isDraftGo: false,
      postCid: "",
    };
  },
  computed: {
    regDate() {
      if (!this.itemData || !this.itemData.regDate) return "";
      return getDateString(this.itemData.regDate);
    },

    noteDecoded() {
      const note = this.itemData.contents;

      if (!note) return "";

      const result = decodeHTMLEntities(document, note);

      if (!getIsValidHtml(result)) return null;

      return result;
    },

    // 물품,하드웨어,서버 -> 물품>하드웨어>서버
    category() {
      if (!this.itemData || !this.itemData.category) return "";
      return getCategoryDepthText(this.itemData.category);
    },

    detailData() {
      const reqPath = this.$route.path;
      const reqTitle = this.itemData.title || "-";
      const refPk = this.itemId;
      const menuId = reqPath.split("/")[1];
      let detailData = {
        refPk,
        reqPath,
        menuId,
        reqTitle,
      };
      return detailData;
    },
    computedPaymentPath() {
      const { docStatus } = this.itemData;
      const ret = getPaymentRouterPath(docStatus);
      return ret;
    },
    isMydraft() {
      const { empNo } = LocalStorageManager.shared.getUserData();
      const { draftEmpNo } = this.itemData;
      return empNo === draftEmpNo;
    },

    ingPayment() {
      const { docStatus, docStatusName, docNo } = this.itemData;
      let ret = true;

      return docNo ? true : false;
    },
  },
  created() {
    this.$store.dispatch(PROGRESS_START_ACTION);
    const { id } = this.$route.params;
    this.itemId = id;
    this.getData(id);
  },

  methods: {
    async getData(draftCid) {
      const path = `${this.$apiPath.ST_PURCHASE_DRAFT}/${draftCid}`;
      const result = await ApiService.shared.getData(path);
      this.$store.dispatch(PROGRESS_END_ACTION);

      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }

      this.itemData = _.cloneDeep(result.data);
    },

    onAlert(text) {
      this.alert(text);
    },

    onClickGoToList() {
      this.$router.push({
        path: this.$routerPath.DRAFT_LIST,
        query: this.$route.query,
      });
    },
    onClickModify() {
      this.$router.push({
        path: `${this.$routerPath.DRAFT_MODIFY}/${this.itemId}`,
      });
    },
    onClickClose() {
      this.isDraftConfirm = false;
      this.isDraftGo = false;
    },

    onClickDraft() {
      this.isDraftConfirm = true;
    },

    onClickConnectPurchaseView(item) {
      this.$windowOpen(`${this.$routerPath.PURCHASE_VIEW}/${item.requestCid}`);
    },

    onClickPdfConvert() {
      this.isDraftConfirm = false;
      this.$refs.PdfConverter.generateReport();
    },
    sendPaymentCb(result) {
      const { code, data, text } = result;
      if (code === "200") {
        this.getData(this.itemId);
        this.isDraftGo = true;
      }
    },
    onClickPlaymentGo() {
      this.$router.push({
        path: `${this.computedPaymentPath}/${this.itemData.docNo}`,
      });
    },
    gotoList() {
      this.$router.push(this.$routerPath.DRAFT_LIST);
    },
    // setPostCid() {
    //   this.$refs.PdfConverter.orgFile = this.itemId;
    // },
  },
};
</script>
